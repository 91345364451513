import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/hero"
import Process from "components/sections/api/process"
import Layers from "components/sections/layers"
import ClientsHome from "components/sections/clientsHome"
import Api from "components/sections/api"
import Apidev from "components/sections/apidev"
import Medias from "components/sections/medias"
import Trial from "components/common/trial"
import Testimonials from "components/sections/testimonials"
import Showcases from "components/sections/showcases"
const IndexPage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout
        footerContent={data.footer.edges}
        headerContent={data.header.edges}
      >
        <SEO
            frontmatter={frontmatter}
        />
        <Hero content={data.hero.edges} />
        {/* Articles is populated via Medium RSS Feed fetch */}
        {/* <About content={data.about.edges} /> */}
        <ClientsHome className="businesses" content={data.clients.edges} />
        <Showcases content={data.showcases.edges} />
        <Process content={data.process.edges} home />
        <Layers content={data.layers.edges} />
        <Api content={data.api.edges} />
        <Apidev content={data.apidev.edges} />
        <div className="gradient">
          <Testimonials content={data.testimonials.edges} slug="" />
          <Medias content={data.medias.edges} />
        </div>
      </Layout>
    </GlobalStateProvider>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/index/hero/" } }) {
      edges {
        node {
          frontmatter {
            greetings
            title
            subtitlePrefix
            subtitle
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/index/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              icon
              width
              height
            }
            items {
              title
              description
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    showcases: allMdx(filter: { fileAbsolutePath: { regex: "/index/showcases/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            showcases {
              name
              description
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP, AVIF], quality: 90)
                }
              }
              button
              link
            }
          }
        }
      }
    }
    process: allMdx(filter: { fileAbsolutePath: { regex: "/api/process/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            processes {
              name
              description
              icon
            }
          }
        }
      }
    }
    layers: allMdx(filter: { fileAbsolutePath: { regex: "/index/layers/" } }) {
      edges {
        node {
          exports {
            shownItems
            items {
              title
              subtitle
              text
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
          frontmatter {
            label
            title1
            title2
            textLeft
            textRight
          }
        }
      }
    }
    api: allMdx(filter: { fileAbsolutePath: { regex: "/index/api/api/" } }) {
      edges {
        node {
          frontmatter {
            label1
            title1
            subtitle1
            label2
            title2
            subtitle2
            description
            developersLink
            corporatesLink
            hintCor
            hintDev
            titleCor
            titleDev
            subtitleCor
            subtitleDev
            imageParsing {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, formats: [WEBP, AVIF], quality: 90)
              }
            }
            imageScoring {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, formats: [WEBP, AVIF], quality: 90)
              }
            }
          }
          exports {
            corporates {
              name
              link
            }
            developers {
              name
              link
            }
          }
        }
      }
    }
    apidev: allMdx(filter: { fileAbsolutePath: { regex: "/index/apidev/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            step1
            step2
            step3
            image1 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            image3 {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    medias: allMdx(filter: { fileAbsolutePath: { regex: "/index/medias/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          exports {
            shownItems
            medias {
              alt
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
        }
      }
    }
    testimonials: allMdx(filter: { fileAbsolutePath: { regex: "/index/testimonials/" } }) {
      edges {
        node {
          frontmatter {
            title
            jobTitle
            author
            photo {
              childImageSharp {
                gatsbyImageData(width: 120, height: 106, placeholder: BLURRED, formats: [WEBP, AVIF], quality: 90)
              }
            }
            text
            buttonText
            textLeftPrefix
            textRightPrefix
            textLeft
            textRight
            bottomText
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`